import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Button, Form, Modal } from "@/atoms"
import { NAMES_ROUTES, VALUES_PARAMS } from "@/const/routes.constants"
import { capitalizeFirstLetter } from "@/helpers/string.helpers"
import { useLogger } from "@/hooks/useLogger"
import { Trans, useTranslation } from "@/hooks/useTranslation"
import { SaveOutlined } from "@/icons"
import { HeaderPage } from "@/organisms/HeaderPage"
import {
  useConditionsOptions,
  useCreatePolicy,
  usePolicies,
  useScopeOptions,
  useUpdatePolicy
} from "@/pages/SettingsPage/application/policies/usePolicies"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"
import { isSuppressSubTypePolicy } from "../../../helpers"
import { AsideBreadcrumbPage } from "../../molecules/AsideBreadcrumbPage"
import { HeaderBreadcrumbPage } from "../../molecules/HeaderBreadcrumbPage"
import { ScmForm } from "../../organisms/ScmForm"
import { PoliciesPageProps } from "../../organisms/ScmTab/ScmTab.types"

export const ScmPoliciesPage = (props: PoliciesPageProps) => {
  const { id, subType } = useParams()
  const isNewPolicy = id === VALUES_PARAMS.NEW_ENTITY
  const needle = isNewPolicy ? undefined : id
  const { response, isLoading, isError } = usePolicies(
    { type: props.policyType },
    needle
  )
  const { response: conditionsOptions, isLoading: conditionsOptionsIsLoading } =
    useConditionsOptions(props.policyType, {
      policyType: capitalizeFirstLetter(subType || "")
    })
  const { response: scopeOptions, isLoading: scopeOptionsIsLoading } =
    useScopeOptions(props.policyType)
  const createContext = useCreatePolicy(props.policyType)
  const updateContext = useUpdatePolicy(props.policyType)
  const navigate = useNavigate()
  const data = response?.data
  const FALLBACK_ROUTE = `/${NAMES_ROUTES.SETTINGS.ROOT}/${props.settingsRoute}/${subType}`
  const { logger } = useLogger()

  useEffect(() => {
    const noDataByPolicyId =
      !isLoading && !scopeOptionsIsLoading && !isNewPolicy && !data?.length

    if (isError || noDataByPolicyId) {
      navigate(`${FALLBACK_ROUTE}/${VALUES_PARAMS.NEW_ENTITY}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const { t } = useTranslation()
  const { t: tPolicies } = useTranslation("translation", {
    keyPrefix: "settings.policies"
  })
  const { t: tMessages } = useTranslation("translation", {
    keyPrefix: "settings.policies.messages"
  })
  const { t: tSections } = useTranslation("translation", {
    keyPrefix: "settings.policies.form.sections"
  })
  const { t: tPolicyType } = useTranslation("translation", {
    keyPrefix: `settings.${props.policyType.toLowerCase()}`
  })

  const [form] = Form.useForm()
  const [modal, modalContextHolder] = Modal.useModal()
  const actions = { createContext, updateContext, navigate }
  const isSaving = createContext.isPending || updateContext.isPending
  const isDirty = Form.useWatch("isDirty", form)
  const isCancelButtonDisabled = isSaving || isLoading
  const isOkButtonDisabled = isCancelButtonDisabled || !isDirty
  const policy = data?.[0]
  const loading =
    isLoading || conditionsOptionsIsLoading || scopeOptionsIsLoading
  const noSubType = !subType
  const detailsTranslationKey = noSubType
    ? "general.details"
    : `details.${subType}`
  const createPolicyTranslationKey = noSubType
    ? "createPolicy"
    : `createBreadcrumb.${subType}`
  const breadcrumbItems = [
    { key: "details", title: tSections(`${detailsTranslationKey}`) },
    { key: "scope", title: tSections("scope") },
    { key: "conditions", title: tSections("conditions") }
  ]
  const isSuppress = isSuppressSubTypePolicy(subType)

  return (
    <PageWithBreadcrumbTemplate
      loading={loading}
      header={
        <HeaderPage
          title={
            <HeaderBreadcrumbPage
              isLoading={false}
              items={[
                {
                  title: t("navigation.settings"),
                  to: `/${NAMES_ROUTES.SETTINGS.ROOT}`
                },
                {
                  title: tPolicyType("title"),
                  to: `${FALLBACK_ROUTE}`
                },
                {
                  title: isNewPolicy
                    ? tPolicies(createPolicyTranslationKey)
                    : `${policy?.name || "..."}`
                }
              ]}
            />
          }
        />
      }
      aside={
        <AsideBreadcrumbPage
          items={
            isSuppress
              ? breadcrumbItems
              : [
                  ...breadcrumbItems,
                  { key: "actions", title: tSections("actions") }
                ]
          }
        />
      }
      footer={
        <div className="container-buttons-form-footer-page-with-breadcrumb-template-settings">
          <Button
            disabled={isCancelButtonDisabled}
            onClick={() => {
              const { id, name } = policy || {}
              logger.info(props.analyticsEvents.CANCEL, { id, name })
              navigate(FALLBACK_ROUTE)
            }}
          >
            {t("general.cancel")}
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            loading={isSaving}
            disabled={isOkButtonDisabled}
            onClick={async () => {
              try {
                await form.validateFields()

                const content = isNewPolicy ? (
                  tMessages("created.guard")
                ) : (
                  <Trans>
                    {tMessages("updated.guard", { name: policy?.name })}
                  </Trans>
                )

                if (isNewPolicy && isSuppress) return form.submit()

                modal.confirm({
                  content,
                  onOk: () => {
                    form.submit()
                  },
                  okText: t("general.save")
                })
              } catch {}
            }}
          >
            {tPolicies(`form.${isNewPolicy ? "create" : "update"}Policy`)}
          </Button>
        </div>
      }
    >
      {!isLoading && (
        <ScmForm
          policyType={props.policyType}
          detailsTranslationKey={detailsTranslationKey}
          isNewPolicy={isNewPolicy}
          form={form}
          response={response}
          actions={actions}
          conditionsOptions={conditionsOptions}
          scopeOptions={scopeOptions}
          logCreatePolicy={(policy, isNew) => {
            const { id, name } = policy
            logger.info(
              isNew ? props.analyticsEvents.CREATE : props.analyticsEvents.EDIT,
              { id, name }
            )
          }}
        />
      )}
      {modalContextHolder}
    </PageWithBreadcrumbTemplate>
  )
}
