import {
  useGetPackages,
  useLoadMoreProjectsByPackage
} from "@/api/useInventory"
import { getCountParams } from "@/organisms/Drawers/hooks/useCounts"
import { getPackages, getProjectsByPackage } from "./useSbomInventory.selector"

import type { Params } from "@/hooks/useHttp"

export const useSbomInventory = (
  params: Params,
  config?: { enabled: boolean; disableCountRequest?: boolean }
) => {
  const { disableCountRequest, ...restConfig } = config || {}

  const context = useGetPackages(params, {
    select: getPackages,
    ...restConfig
  })

  const contextCount = useGetPackages(
    { ...params, ...getCountParams() },
    { enabled: !disableCountRequest && (config?.enabled ?? true) }
  )

  return [context, contextCount] as const
}

export const useProjectsByPackage = (
  params: Params<{ needle: string }>,
  config?: any
) => {
  const context = useLoadMoreProjectsByPackage(params, {
    select: getProjectsByPackage,
    ...config
  })

  return context
}
