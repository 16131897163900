import { Popover, Tag, Text } from "@/atoms"
import { Image, SourceControlIcon } from "@/icons"
import { Resource as ResourceComponent } from "@/pages/TriagePage/components/molecules/ResourceCell/Resource"

import styles from "./ResourceCell.module.scss"

import type { Resource } from "@/domain/issue"

export const ResourceCell =
  (onRowClick?: (record: { key: string }, type: boolean) => void) =>
  (resource: Resource) => {
    const { name, sourceControl, tag, baseImageDetailsExist } = resource
    const nameWithTag = `${name}${tag ? `:${tag}` : ""}`
    const subTitle = `Tag: ${tag}`
    const Wrapper = onRowClick ? Popover : "div"

    return (
      <div className={styles.container}>
        <span className={styles.icon}>
          {sourceControl ? (
            <SourceControlIcon sourceControl={sourceControl} />
          ) : (
            <Image />
          )}
        </span>

        <Wrapper
          destroyTooltipOnHide
          placement="topLeft"
          rootClassName="popover-resource-cell"
          className={styles.titleSection}
          content={
            !!onRowClick
              ? ((() => (
                  <Text
                    underline
                    className={styles.textPopover}
                    onClick={(e) => {
                      e.stopPropagation()
                      onRowClick?.({ key: resource.id }, !!sourceControl)
                    }}
                  >
                    <ResourceComponent
                      type={
                        sourceControl ? "CodeRepository" : "ImageRepository"
                      }
                      name={nameWithTag}
                      sourceControl={sourceControl}
                    />
                  </Text>
                )) as any)
              : ""
          }
        >
          <span>{name}</span>
          {tag && <span className={styles.subTitle}>{subTitle}</span>}
        </Wrapper>
        {!!baseImageDetailsExist && (
          <Tag className={styles.tag}>From Base Image</Tag>
        )}
      </div>
    )
  }
