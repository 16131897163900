import { Button, Dropdown, Tooltip } from "@/atoms"
import { useExport } from "@/hooks/useExport"
import { useLogger } from "@/hooks/useLogger"
import { DownloadOutlined } from "@ant-design/icons"

import { useSearchParams } from "react-router-dom"
import type { ExportButtonProps } from "."
import { getExportItems } from "./getExportItems.helper"

export const ExportButton = ({
  exportType,
  isLoading,
  filters
}: ExportButtonProps) => {
  const exportItems = getExportItems(exportType, filters)
  const { tasksByUrl, addTask } = useExport()
  const { logger } = useLogger()
  const {
    url: keyByUrl,
    label,
    queryStringParams = [],
    params,
    analytic,
    excludeParams = false
  } = exportItems[0]
  const [searchParams] = useSearchParams()
  const isDisabled = !!tasksByUrl[keyByUrl] || isLoading
  const showButton =
    window.location.hostname.includes("kodemsecurity.dev") ||
    window.location.hostname.includes("localhost")
  if (exportItems.length > 1) {
    return (
      <Dropdown
        disabled={isDisabled}
        menu={{
          items: exportItems,
          onClick: ({ key }) => {
            const result = exportItems.find((item) => item.key === key)

            if (result) {
              addTask(result.url, result.params)

              if (analytic) {
                const { event, type } = analytic

                logger.info(event, { type })
              }
            }
          }
        }}
        trigger={["click"]}
      >
        <Button disabled={isDisabled} icon={<DownloadOutlined />} />
      </Dropdown>
    )
  }
  const queryParams = params
    ? new URLSearchParams(params.filters)
    : searchParams
  const include = () => {
    const params = queryStringParams.reduce(
      (acc, name) => {
        const value = queryParams.get(name)

        if (value) acc[name] = value

        return acc
      },
      {} as Record<string, string>
    )
    return params
  }

  const exclude = () => {
    queryStringParams.forEach((name) => {
      queryParams.delete(name)
    })

    const result: Record<string, string> = {}
    queryParams.forEach((value, key) => {
      result[key] = value
    })

    return result
  }
  const calculatedParams = excludeParams ? exclude() : include()
  return (
    <>
      {showButton && (
        <Tooltip title={label}>
          <Button
            disabled={isDisabled}
            icon={<DownloadOutlined />}
            onClick={() => addTask(keyByUrl, calculatedParams, analytic)}
          />
        </Tooltip>
      )}
    </>
  )
}
