import { SEVERITIES_FILTER_OPTIONS } from "@/const/options.constants"

import { ExportButton } from "@/molecules/ExportButton"
import { Filter } from "@/organisms/FilterNew/Filter"
import { InputSmartSearchLoading, SmartSearch } from "@/organisms/SmartSearch"

import type { SbomFilterComponent } from "."

export const SbomFilter: SbomFilterComponent = (props) => {
  const { items, isLoading, previewMode, ...attr } = props

  if (previewMode) return null

  return (
    <Filter.Provider keys={items} {...attr}>
      <Filter.Template
        isLoading={isLoading}
        aside={<ExportButton isLoading exportType="Sbom" />}
        search={
          isLoading ? (
            <InputSmartSearchLoading />
          ) : (
            <SmartSearch id="smart-search-sbom-tab-inventory" filters={items} />
          )
        }
      >
        <Filter.Insights filters={items} isLoading={isLoading} />
        <Filter.Checkbox
          isLoading={isLoading}
          order="none"
          keyFilter="severity"
          items={SEVERITIES_FILTER_OPTIONS}
          placeHolderDefaultValue="Severity"
        />
      </Filter.Template>
    </Filter.Provider>
  )
}
