import { BaseImage, Image, SourceControlIcon } from "@/icons"

import type { IconResourceComponent } from "."

export const IconResource: IconResourceComponent = (props) => {
  const { type, sourceControl } = props

  if (!type) return null
  if (type === "ImageRepository") return <Image />
  if (type === "Image") return <BaseImage />

  return <SourceControlIcon sourceControl={sourceControl} />
}
