import { useTranslation } from "@/hooks/useTranslation"
import { Language } from "@/icons"
import { CopyLinkButton } from "@/molecules/CopyLinkButton"
import { Resources } from "@/molecules/Resources"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import { usePackageDescribe } from "@/organisms/Drawers/applications/inventory/useInventory"
import { splitPackageIdFromDrawerRecord } from "@/organisms/Drawers/applications/inventory/useInventory.selectors"
import { SkeletonTitle } from "@/organisms/Drawers/components/SkeletonTitle"
import { PACKAGE_DRAWER_ID } from "@/organisms/Drawers/const"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { useLink } from "@/organisms/Drawers/hooks/useLink"

import styles from "./TitlePackageDrawer.module.scss"

import type { TitlePackageDrawerComponent } from "."

export const TitlePackageDrawer: TitlePackageDrawerComponent = (props) => {
  const { t } = useTranslation()
  const values = splitPackageIdFromDrawerRecord(props)
  const { response, isLoading } = usePackageDescribe(values)
  const loading = isLoading || props.isLoading
  const { data } = response || {}
  const { name, language, version, resources = [] } = data || {}
  const linkToDrawer = useLink(PACKAGE_DRAWER_ID)
  const { onRowClick } = useDrawer("")

  if (loading) {
    return <SkeletonTitle />
  }

  return (
    <TitleDrawer
      loading={loading}
      icon={<Language language={language} />}
      title={name}
      subTitle={
        <span className={styles.subTitle}>
          <span>{`${t("general.version")}: ${version} on`}</span>
          <Resources
            inline
            popover
            resources={resources}
            t={t}
            classes={{
              container: styles.containerResource,
              name: styles.nameResource
            }}
            onRowClick={onRowClick}
          />
        </span>
      }
      aside={!!linkToDrawer && <CopyLinkButton link={linkToDrawer} />}
    />
  )
}
