import { SeverityTag } from "@/molecules/SeverityTag"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { InsightsTriageCell } from "@/pages/TriagePage/components/molecules/InsightsTriageCell"

import type { ScoreExplanationProps } from "@/api/useTriage.types"
import type { TFunction } from "@/hooks/useTranslation"

export const getItems = (
  record: ScoreExplanationProps,
  { t, internetFacingFF }: { t: TFunction; internetFacingFF?: boolean }
) => {
  const insights = {
    tag: true,
    runtime: false,
    internetFacing: false,
    ingress: false,
    inTheWildExploitMaturity: false,
    pocExploitMaturity: false
  }

  const { riskInsights, epss, cvss, severity } = record
  const exposure =
    riskInsights.internetFacing === "YES" || !!riskInsights.ingress
  const emptyValue = "-"
  const epssValue = !!epss ? `${(Number(epss) * 100).toFixed(2)}%` : epss

  return [
    {
      label: (
        <OverviewDescriptions.Label>
          {t("general.severity")}
        </OverviewDescriptions.Label>
      ),
      children: (
        <span>
          <SeverityTag t={t} severity={severity} />
          <span>{!!cvss && ` (CVSS ${cvss})`}</span>
        </span>
      )
    },
    {
      label: (
        <OverviewDescriptions.Label>
          {t("general.usage")}
        </OverviewDescriptions.Label>
      ),
      className: "tag-score-explanation",
      children: riskInsights.runtime
        ? InsightsTriageCell(
            t,
            !!internetFacingFF
          )({
            ...insights,
            runtime: riskInsights.runtime
          })
        : emptyValue
    },
    {
      label: <OverviewDescriptions.Label>EPSS</OverviewDescriptions.Label>,
      children: epssValue ?? emptyValue
    },
    {
      label: (
        <OverviewDescriptions.Label>
          Exploit Maturity
        </OverviewDescriptions.Label>
      ),
      children:
        riskInsights.exploitMaturity === "IN_THE_WILD"
          ? InsightsTriageCell(
              t,
              !!internetFacingFF
            )({
              ...insights,
              inTheWildExploitMaturity:
                riskInsights.exploitMaturity === "IN_THE_WILD"
            })
          : emptyValue,
      className: "tag-score-explanation"
    },
    {
      label: (
        <OverviewDescriptions.Label>
          {t("general.exposure")}
        </OverviewDescriptions.Label>
      ),
      children: exposure
        ? InsightsTriageCell(
            t,
            !!internetFacingFF
          )({
            ...insights,
            internetFacing: riskInsights.internetFacing === "YES",
            ingress: riskInsights.ingress
          })
        : emptyValue,
      className: "tag-score-explanation"
    }
  ]
}
