import { ImageTabData } from "@/pages/InventoryPage/application/image-inventory/useImageInventory.selector"
import { ExportInventoryItem } from "../../organisms/ExportInventoryItem"
import styles from "./ImageActionsCell.module.scss"

export const ImageActionsCell = (image: ImageTabData) => {
  if (!image) return null

  return (
    <div className={styles.container}>
      <ExportInventoryItem
        itemId={image.imageId}
        itemType="container_image"
        filename={`${image.displayName}-SPDX-report.json`}
      />
    </div>
  )
}
