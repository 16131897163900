import cn from "classnames"

import { HiddenValueCounter } from "@/atoms"

import styles from "./InsightsIcons.module.scss"

import type { InsightsIconsProps } from "."

export const InsightsIcons = (props: InsightsIconsProps) => {
  const { items, values, hide, limit, isKeyTranslation, noTooltip } = props
  const { triggerable } = props // TODO: temporary class for demo }
  const useLimit = !!limit
  const valuesIsArray = Array.isArray(values)
  let counter = 0

  return (
    <div className={cn(styles.container, triggerable && styles.triggerable)}>
      {items.map((item, index: number) => {
        const value = valuesIsArray ? values[index] : values[item.key]
        if (hide && !value) return null
        if (useLimit) {
          if (value) counter += 1
          if (counter > limit) return null
        }

        return (
          <item.Icon
            key={index}
            data-tooltip-content={
              !noTooltip ? `insights.${item.key}` : undefined
            }
            data-tooltip-translation={isKeyTranslation}
            className={cn(styles.icon, !!value && styles.active)}
          />
        )
      })}
      {useLimit && counter > limit && (
        <HiddenValueCounter value={counter - limit} />
      )}
    </div>
  )
}
