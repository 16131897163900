import { codeRepoLanguages } from "@/api/useInventory.types"
import {
  EXPLOITABILITY_FILTER_OPTIONS,
  SEVERITIES_FILTER_OPTIONS
} from "@/const/options.constants"
import {
  getCVEFilter,
  InventoryFilters
} from "@/pages/InventoryPage/utils/filters.utils"

import type { TFunction } from "@/hooks/useTranslation"
import type { Filters } from "@/organisms/SmartSearch/types/options.types"

export const getDefaultFilters = (t: TFunction): Filters => [
  {
    key: "severity",
    label: t("filters.severity"),
    type: "multiple",
    options: SEVERITIES_FILTER_OPTIONS,
    helper: [
      {
        label: "severity:value1,value2",
        description: "Equals value1 OR value2"
      }
    ]
  },
  {
    key: "packageLanguage",
    label: t("filters.languages"),
    type: "multiple",
    options: codeRepoLanguages.map((v) => ({ label: v, value: v })),
    helper: [
      {
        label: "Languages: value1, value2",
        description: "Matches package language as substring"
      }
    ]
  },
  {
    key: "packageLicense",
    label: t("filters.license"),
    type: "input",
    helper: [
      {
        label: "License: value1, value2",
        description: "Matches multiple licenses as substring"
      }
    ]
  },
  {
    key: "packageName",
    label: t("filters.package"),
    type: "input",
    helper: [
      {
        label: "Package: value1, value2",
        description: "Matches package names as a substring"
      }
    ]
  },
  {
    key: "packageVersion",
    label: t("filters.version"),
    type: "input",
    helper: [
      {
        label: "Version: value1, value2",
        description: "Matches multiple versions as substring"
      }
    ]
  },
  {
    key: "baseImageName",
    label: t("filters.baseImageName"),
    type: "input",
    helper: [
      {
        description: "Matches base image name as substring"
      }
    ]
  },
  {
    key: "isVulnerable",
    label: t("filters.vulnerable"),
    type: "single",
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO" }
    ]
  },
  getCVEFilter(),
  {
    key: "cweId",
    label: t("filters.cweId").toLowerCase(),
    displayLabel: t("filters.cweId"),
    type: "input",
    helper: [
      {
        label: "CWE-id: value1, value2",
        description: "Matches multiple cwe identifiers (e.g. cwe-506)"
      }
    ]
  },
  {
    key: "cweDescription",
    label: t("filters.cweName").toLowerCase(),
    displayLabel: t("filters.cweName"),
    type: "input",
    helper: [
      {
        label: "CWE-name: value1, value2",
        description: 'Matches multiple cwe name (e.g. "Prototype Pollution")'
      }
    ]
  },
  {
    key: "packageExploitability",
    label: t("filters.exploitability"),
    type: "multiple",
    options: EXPLOITABILITY_FILTER_OPTIONS
  },
  {
    key: "isPackageRuntime",
    label: t("filters.runtime"),
    type: "single",
    order: 1,
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO", disabled: true },
      { label: "N/A", value: "UNDETERMINED", disabled: true }
    ],
    helper: [{ description: "Packages that are seen in runtime" }]
  },
  {
    key: "isPackageIngress",
    label: t("filters.ingress"),
    type: "single",
    order: 3,
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO" },
      { label: "N/A", value: "UNDETERMINED", disabled: true }
    ]
  },
  {
    key: "isPackageDirect",
    label: t("filters.isDirect"),
    order: 4,
    type: "single",
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO" },
      { label: "N/A", value: "UNDETERMINED", disabled: true }
    ]
  },
  {
    key: "isPackageInDirect",
    label: t("filters.isIndirect"),
    order: 5,
    type: "single",
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO" },
      { label: "N/A", value: "UNDETERMINED", disabled: true }
    ]
  },
  {
    key: "isPackageFromBaseImage",
    label: t("filters.fromBaseImage"),
    order: 6,
    type: "single",
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO" },
      { label: "N/A", value: "UNDETERMINED", disabled: true }
    ]
  },

  InventoryFilters.application(t),
  InventoryFilters.repositoryName(t),
  InventoryFilters.imageName(t),
  InventoryFilters.namespace(t)
]

export const getFilters = (
  t: TFunction,
  props?: { internetFacingFF?: boolean }
) => {
  if (props?.internetFacingFF) {
    const filters = getDefaultFilters(t).map((filter) => {
      if (filter.options) {
        filter.options.push({ label: "N/A", value: "", disabled: true })
      }
      return filter
    })

    return [
      {
        key: "isPackageInternetFacing",
        label: t("filters.internetFacing"),
        type: "single",
        order: 2,
        options: [
          { label: "Yes", value: "YES" },
          { label: "No", value: "NO" },
          { label: "N/A", value: "UNDETERMINED" }
        ]
      },
      ...filters
    ]
  }

  return getDefaultFilters(t)
}
