import { useRef } from "react"

import { Link } from "@/atoms"
import { ApiOutlined, CloudUploadOutlined, SettingOutlined } from "@/icons"
import { MenuNavigationTemplate } from "@/templates/MenuNavigationTemplate"
import { TitleSideNavigation as Title } from "./TitleSideNavigation"

import styles from "../SideNavigation.module.scss"

import type { NavigationComponent } from ".."

export const ManageSideNavigation: NavigationComponent = (props) => {
  const { collapsed, t } = props
  const menuItems = useRef([
    {
      label: (
        <Link className="capitalize-text-ks" to="/deployments">
          {t("navigation.deployments")}
        </Link>
      ),
      key: "deployments",
      icon: <CloudUploadOutlined />,
      className: styles.newItem,
      "data-testid": "deployments"
    },
    {
      label: (
        <Link className="capitalize-text-ks" to="/integrations">
          {t("navigation.integrations")}
        </Link>
      ),
      key: "integrations",
      icon: <ApiOutlined />,
      "data-testid": "integrations"
    },
    {
      label: (
        <Link className="capitalize-text-ks" to="/settings">
          {t("navigation.settings")}
        </Link>
      ),
      key: "settings",
      icon: <SettingOutlined />
    }
  ]).current

  return (
    <>
      <Title collapsed={collapsed}>{t("general.manage")}</Title>
      <MenuNavigationTemplate items={menuItems} />
    </>
  )
}
