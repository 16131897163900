import { useRef } from "react"

import { Link } from "@/atoms"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import {
  BaseImage,
  CodeRepo,
  DeploymentUnitOutlined,
  Image,
  Package
} from "@/icons"
import { MenuNavigationTemplate } from "@/templates/MenuNavigationTemplate"
import { TitleSideNavigation as Title } from "./TitleSideNavigation"

import type { NavigationComponent } from ".."

export const ExploreSideNavigation: NavigationComponent = (props) => {
  const { collapsed, t } = props
  const menuItems = useRef([
    {
      label: (
        <Link
          className="capitalize-text-ks"
          to={`/inventory/${TAB_KEYS_BY_PAGE.INVENTORY[0]}`}
        >
          {t("general.code_repositories")}
        </Link>
      ),
      key: "branch",
      icon: <CodeRepo />,
      "data-testid": "branch.inventory"
    },
    {
      label: (
        <Link
          className="capitalize-text-ks"
          to={`/inventory/${TAB_KEYS_BY_PAGE.INVENTORY[1]}`}
        >
          {t("general.application_other")}
        </Link>
      ),
      key: "applications",
      icon: <DeploymentUnitOutlined />,
      "data-testid": "application.inventory"
    },
    {
      label: (
        <Link
          className="capitalize-text-ks"
          to={`/inventory/${TAB_KEYS_BY_PAGE.INVENTORY[2]}`}
        >
          {t("general.image_other")}
        </Link>
      ),
      key: "images",
      icon: <Image />,
      "data-testid": "image.inventory"
    },
    {
      label: (
        <Link
          className="capitalize-text-ks"
          to={`/inventory/${TAB_KEYS_BY_PAGE.INVENTORY[3]}`}
        >
          {t("general.base_image_other")}
        </Link>
      ),
      key: "baseImages",
      icon: <BaseImage />,
      "data-testid": "base_image.inventory"
    },
    {
      label: (
        <Link
          className="capitalize-text-ks"
          to={`/inventory/${TAB_KEYS_BY_PAGE.INVENTORY[4]}`}
        >
          {t(`general.sbom_title`, { title: t("general.package_other") })}
        </Link>
      ),
      key: "sbom",
      icon: <Package />,
      "data-testid": "sbom.inventory"
    }
  ]).current

  return (
    <>
      <Title collapsed={collapsed}>{t("navigation.inventory")}</Title>
      <MenuNavigationTemplate items={menuItems} path="/inventory/:itemKey" />
    </>
  )
}
