import { useMatch } from "react-router-dom"

import { Menu } from "@/atoms"

import styles from "./MenuNavigationTemplate.module.scss"

import type { MenuNavigationTemplateProps } from "./MenuNavigationTemplate.types"

export const MenuNavigationTemplate = ({
  items,
  path = "/:itemKey"
}: MenuNavigationTemplateProps) => {
  const itemKey = useMatch({ path, end: false })?.params?.itemKey || "/"

  return (
    <Menu
      theme="dark"
      selectedKeys={[itemKey]}
      mode="inline"
      items={items}
      className={styles.menuNavigationTemplate}
    />
  )
}
