import { splitPackageIdFromDrawerRecord } from "@/organisms/Drawers/applications/inventory/useInventory.selectors"
import { IssuesTab } from "@/organisms/Drawers/components/IssuesTab"
import { geDefaultFiltersPackageDrawer } from "@/organisms/Drawers/hooks/useParamsFilter"

export const IssueTabPackageDrawer = (props: any) => {
  const values = splitPackageIdFromDrawerRecord(props)

  return (
    <IssuesTab
      getInitialParamsFilter={() => ({})}
      defaultParams={geDefaultFiltersPackageDrawer(values)}
      {...props}
    />
  )
}
