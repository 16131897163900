import { useState } from "react"

import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { scrollToElement } from "@/helpers/table.helpers"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useQueryString } from "@/hooks/useQueryString"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { ExpandIconTable } from "@/molecules/ExpandIconTable"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { useSbomInventory } from "@/pages/InventoryPage/application/sbom-inventory/useSbomInventory"
import { TabTemplate } from "@/templates/TabTemplate"
import { ExpandedRowSbomTab } from "../ExpandedRowSbomTab"
import { SbomFilter } from "../SbomFilter"
import { getColumnsTable } from "./getColumnsTable"
import { getDefaultFilters } from "./getItemsFilter"

import type { SbomTabComponent } from "."

export const SbomTab: SbomTabComponent = ({ useActiveKeyTabsInventory }) => {
  const { onSorterChange, ...orderByProps } = useOrderBy()
  const { t } = useTranslation()
  const convertors = useNumberConvertor()
  const { onRowClick } = useDrawer("")
  const columns = getColumnsTable({ t, convertors, orderByProps, onRowClick })
  const { activeKey, config = {} } = useActiveKeyTabsInventory()
  const previewMode = !!config.headers
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")
  const filterItems = getDefaultFilters(t)
  const TAB_KEYS = TAB_KEYS_BY_PAGE.INVENTORY
  const params = useQueryString(["orderBy", "ascending", ...filterItems])
  const [{ response, isLoading }] = useSbomInventory(params, {
    enabled: activeKey === TAB_KEYS[4],
    disableCountRequest: true
  })
  const { data, metadata } = response || {}
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

  useTooltip(data)

  return (
    <TabTemplate
      columns={columns}
      dataSource={data}
      metadata={metadata}
      loading={isLoading}
      expandable={{
        expandedRowKeys,
        expandIcon: ExpandIconTable,
        expandRowByClick: true,
        rowExpandable: ({ expandable }) => !!expandable,
        expandedRowRender: ({ key }, _index, _, expanded) => (
          <ExpandedRowSbomTab
            packageId={key}
            expanded={expanded}
            params={params}
            onCollapsed={() => {
              scrollToElement(key, _index)

              setExpandedRowKeys(
                expandedRowKeys.filter((value) => value !== key)
              )
            }}
          />
        ),
        onExpand: (expanded, record) => {
          if (expanded) setExpandedRowKeys([record.key, ...expandedRowKeys])
          else
            setExpandedRowKeys(
              expandedRowKeys.filter((value) => value !== record.key)
            )
        }
      }}
      SearchComponent={
        <SbomFilter
          items={filterItems}
          isLoading={internetFacingFF === undefined}
          previewMode={previewMode}
        />
      }
      onChange={(_pagination, _filters, sorter) => {
        onSorterChange(sorter)
      }}
    />
  )
}
