import { SEVERITIES_FILTER_OPTIONS } from "@/const/options.constants"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { TEASERS } from "@/const/teaser.constants"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { APPLICATION_DRAWER_ID } from "@/organisms/Drawers/const"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { Filter } from "@/organisms/FilterNew/Filter"
import { InputSmartSearchLoading, SmartSearch } from "@/organisms/SmartSearch"
import { useApplicationInventory } from "@/pages/InventoryPage/application/application-inventory/useApplicationInventory"
import { TabTemplate } from "@/templates/TabTemplate"
import { getColumnsTable } from "./getColumnsTable"
import { getFilters } from "./getItemsFilter"

import { DatePickerExportContainer } from "@/organisms/DatePickerExportContainer"
import type { ApplicationTabProps } from "."

export const ApplicationsTab: React.FC<ApplicationTabProps> = ({
  useActiveKeyTabsInventory
}) => {
  const TAB_KEYS = TAB_KEYS_BY_PAGE.INVENTORY
  const { activeKey, config = {} } = useActiveKeyTabsInventory()
  const previewMode = !!config.headers
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")
  const { t } = useTranslation()
  const filterItems = getFilters(t, { internetFacingFF })
  const convertors = useNumberConvertor()
  const { onSorterChange, ...orderByProps } = useOrderBy()

  const columns = getColumnsTable({
    internetFacingFF,
    previewMode,
    convertors,
    sorter: orderByProps,
    t
  })

  const { response, isLoading } = useApplicationInventory(filterItems, {
    enabled: activeKey === TAB_KEYS[1],
    ...config
  })
  const { data, metadata } = response || {}

  const dataReadinessFF = useFeatureFlags("dataReadiness")
  const filterDisabled = !!dataReadinessFF || internetFacingFF === undefined
  const { idsInDrawers, onRowClick } = useDrawer(APPLICATION_DRAWER_ID, {
    filters: ["severity", "riskId", "runtime"]
  })

  useTooltip(data)

  return (
    <TabTemplate
      resizable
      dataSource={data}
      metadata={metadata}
      columns={columns}
      loading={isLoading}
      locale={{
        teasers: {
          dataReadiness: true,
          kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
        }
      }}
      idsInDrawers={idsInDrawers}
      onRowClick={onRowClick}
      disableDrawer={previewMode}
      SearchComponent={
        !previewMode && (
          <Filter.Provider keys={Object.keys(filterItems)}>
            <Filter.Template
              isLoading={filterDisabled}
              aside={
                <DatePickerExportContainer
                  isLoading={filterDisabled || isLoading}
                  exportType="Application"
                />
              }
              search={
                filterDisabled ? (
                  <InputSmartSearchLoading />
                ) : (
                  <SmartSearch
                    id="smart-search-application-tab-inventory"
                    filters={filterItems}
                  />
                )
              }
            >
              <Filter.Insights
                filters={filterItems}
                isLoading={filterDisabled}
              />
              <Filter.Checkbox
                isLoading={isLoading}
                order="none"
                keyFilter="severity"
                items={SEVERITIES_FILTER_OPTIONS}
                placeHolderDefaultValue="Severity"
              />
            </Filter.Template>
          </Filter.Provider>
        )
      }
      onChange={(_pagination, _filters, sorter) => {
        onSorterChange(sorter)
      }}
    />
  )
}
