import { useEffect, useRef, useState } from "react"

import { RESOURCES_NAMES } from "@/const/resource.constants"
import { TEASERS } from "@/const/teaser.constants"
import { scrollToElement } from "@/helpers/table.helpers"
import { useHttp } from "@/hooks/useHttp"
import { useLogger } from "@/hooks/useLogger"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { ExpandIconTable } from "@/molecules/ExpandIconTable"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { LabelModal } from "@/organisms/LabelModal"
import {
  actions,
  useSelection
} from "@/pages/TriagePage/application/useSelection"
import { useProjectsTriage } from "@/pages/TriagePage/application/useTriage"
import { getFilters } from "@/pages/TriagePage/application/useTriage.selector"
import { TabTemplate } from "@/templates/TabTemplate"
import { IssueActions } from "../../molecules/IssueActions"
import { TotalPagination } from "../../molecules/TotalPagination"
import { ExpandedRowTriageTab } from "../ExpandedRowTriageTab"
import { FilterTriageTab } from "../FilterTriageTab"
import { getColumnsTable } from "./getColumnsTable"

export const TriageTab = () => {
  const { t } = useTranslation()
  const internetFacingFF = useFeatureFlags("internetFacingIntegration")
  const convertors = useNumberConvertor()
  const { onSorterChange, ...orderByProps } = useOrderBy()
  const { onRowClick } = useDrawer("")
  const [record, setRecord] = useState(null)
  const columns = getColumnsTable({
    t,
    convertors,
    orderByProps,
    onRowClick,
    internetFacingFF: !!internetFacingFF
  })

  const filterKeysRef = useRef<string[]>([])

  useEffect(() => {
    filterKeysRef.current = getFilters({
      t,
      internetFacingFF: !!internetFacingFF
    }).map(({ key }) => key)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internetFacingFF])

  const isEnabled = internetFacingFF !== undefined
  const [{ response, isLoading: projectsIsLoading }, params] =
    useProjectsTriage(filterKeysRef.current, isEnabled)
  const isLoading = projectsIsLoading || !isEnabled

  const countContext = useHttp<{ data: number }>(
    `${RESOURCES_NAMES.TRIAGE.ISSUES_COUNT}`,
    params
  )
  const { data, metadata } = response || {}
  const { logger, EVENTS } = useLogger()
  const [selectionState, dispatch] = useSelection()

  useTooltip(data)

  return (
    <>
      <TabTemplate
        loading={isLoading}
        dataSource={data}
        columns={columns}
        metadata={metadata}
        locale={{
          teasers: {
            dataReadiness: true,
            kortexInstalled: TEASERS.SCM_INSTALLED.codeRepoNotConnected(t),
            scmInstalled: TEASERS.SCM_INSTALLED.codeRepoNotConnected(t)
          }
        }}
        rowSelection={{
          type: "checkbox",
          hideSelectAll: true,
          getCheckboxProps: (record) => {
            const currentRowState = selectionState.items[record.key] || {}
            const { nestedItems, updating } = currentRowState
            const disabled =
              !record.expandable || nestedItems?.length === 0 || updating

            return {
              disabled,
              indeterminate: currentRowState.selectedState === "indeterminate"
            }
          },
          selectedRowKeys: selectionState.selectedParentIds,
          onSelect: (record, selected) => {
            dispatch(actions.onSelectAllRowsInNestedTable(record.key, selected))
          }
        }}
        expandable={{
          expandIcon: ExpandIconTable,
          expandedRowRender: ({ key }, _index, _, expanded) => (
            <ExpandedRowTriageTab
              projectId={key}
              filterKeys={filterKeysRef.current}
              expanded={expanded}
              t={t}
              setRecord={setRecord}
              onCollapsed={() => {
                scrollToElement(key)

                dispatch(actions.expandedParentRow(key, false))
              }}
            />
          ),
          expandRowByClick: true,
          expandedRowKeys: selectionState.expandedRowKeys,
          rowExpandable: ({ expandable }) => !!expandable,
          onExpand: (expanded, record) => {
            if (expanded) {
              const resources = record?.resources || []
              const { id, name, type } = resources?.[0] || {}

              logger.info(EVENTS.ANALYTIC_EVENTS.TRIAGE.EXPAND_ROW, {
                id,
                name,
                type,
                relatedResources: resources.length - 1
              })
            }

            dispatch(actions.expandedParentRow(record.key, expanded))
          }
        }}
        onChange={(_pagination, _filters, sorter) => {
          onSorterChange(sorter)
        }}
        SearchComponent={<FilterTriageTab />}
        asideFooter={
          <IssueActions
            params={params}
            componentName="TriageTab"
            showSelctedProjects
          />
        }
        totalRender={({ loading, total }) => (
          <TotalPagination
            projects={total}
            issues={countContext?.response?.data}
            isLoading={loading || countContext?.isLoading}
          />
        )}
      />
      {record && (
        <LabelModal
          placement="tooltip"
          component="triage-tab"
          record={record}
          onClose={() => setRecord(null)}
        />
      )}
    </>
  )
}
