import cn from "classnames"
import { useState } from "react"
import { Outlet } from "react-router-dom"

import { Button, Layout, Link, LogoImage } from "@/atoms"
import { noScopeSelected } from "@/domain/user"
import { useLogger } from "@/hooks/useLogger"
import { useUser } from "@/hooks/useUser"
import { LeftOutlined, RightOutlined } from "@/icons"
import { LayoutErrorBoundary } from "@/organisms/LayoutErrorBoundary"
import { NoScopeResult } from "@/organisms/NoScopeResult"
import { SideNavigation } from "@/organisms/SideNavigation"

import styles from "./LayoutTemplate.module.scss"

export const LayoutTemplate = () => {
  const { logger } = useLogger()
  const [collapsed, setCollapsed] = useState(false)
  const { Sider } = Layout
  const showInfoBanner = false
  const { user } = useUser()
  const showScopePlaceHolder = noScopeSelected(user)

  return (
    <Layout className={styles.containerLayoutTemplate}>
      <Sider width={208} collapsed={collapsed} onCollapse={setCollapsed}>
        <Button
          size="small"
          className={styles.collapsedButton}
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => setCollapsed(!collapsed)}
        />
        <div
          className={cn(
            styles.logoContainerLayoutTemplate,
            collapsed && styles.collapsed
          )}
        >
          <Link to="/">
            <LogoImage dark height={32} />
          </Link>
        </div>
        <SideNavigation collapsed={collapsed} />
      </Sider>
      <Layout
        id="outlet-layout-container"
        className={cn(
          styles.outletSection,
          collapsed && styles.collapsed,
          showInfoBanner && styles.showInfoBanner
        )}
      >
        <LayoutErrorBoundary user={user} logger={logger}>
          {showInfoBanner && (
            <div className={styles.infoBanner}>
              You are viewing sample data.
            </div>
          )}
          {showScopePlaceHolder && <NoScopeResult />}
          {!showScopePlaceHolder && <Outlet />}
        </LayoutErrorBoundary>
      </Layout>
    </Layout>
  )
}
