import cn from "classnames"

import { Spin } from "@/atoms"

import styles from "./PageWithBreadcrumbTemplate.module.scss"

import type { PageWithBreadcrumbTemplateProps } from "."

export const PageWithBreadcrumbTemplate: React.FC<
  PageWithBreadcrumbTemplateProps
> = ({ children, header, aside, footer, full, loading, classes = {} }) => (
  <div className={styles.container}>
    <div className={styles.header}>{header}</div>
    {aside && (
      <div
        id="aside-page-with-breadcrumbs-template"
        className={cn(
          styles.aside,
          !!footer && styles.withFooter,
          loading && styles.loading
        )}
      >
        <div>{aside}</div>
        {loading && <Spin />}
        {!loading && <div>{children}</div>}
      </div>
    )}
    {!aside && (
      <div
        className={cn(
          styles.main,
          full && styles.full,
          loading && styles.loading,
          classes.main
        )}
        id="main-page-with-breadcrumbs-template"
      >
        {loading && <Spin />}
        {!loading && children}
      </div>
    )}
    {footer && <div className={styles.footer}>{footer}</div>}
  </div>
)
