import { useEffect, useState } from "react"

import { RESOURCES_NAMES } from "@/const/resource.constants"
import { predicateHelpers } from "@/hooks/useHttp"
import { useTranslation } from "@/hooks/useTranslation"
import { useGetIssuesForResource } from "@/organisms/Drawers/applications/inventory/useInventory"
import { IssuesFilter } from "@/organisms/Drawers/components/IssuesFilter"
import { TabDrawerTemplate } from "@/organisms/Drawers/components/TabDrawerTemplate"
import { useCounts } from "@/organisms/Drawers/hooks/useCounts"
import { useParamsFilter } from "@/organisms/Drawers/hooks/useParamsFilter"
import { LabelModal } from "@/organisms/LabelModal"
import {
  actions,
  useSelection
} from "@/pages/TriagePage/application/useSelection"
import { IssueActions } from "@/pages/TriagePage/components/molecules/IssueActions"
import { expandedRowRenderFunction } from "./ExpandedRow"
import { getColumnsTable } from "./getColumnsTable"

import type { Issue } from "@/domain/issue"
import type { IssuesTabProps } from "./IssuesTab.types"

export const Tab = (props: IssuesTabProps) => {
  const tabKey = props.tabKey || "scaIssues"
  const { getInitialParamsFilter, record, headerComponent, exportQueryParams } =
    props
  const filtersContext = useParamsFilter({
    filterKey: tabKey,
    initialParams: getInitialParamsFilter(),
    ...props
  })

  const [dataContext, countsContext] = useGetIssuesForResource(
    record?.id,
    filtersContext.params
  )
  const { t } = useTranslation()
  const [selectionState, dispatch] = useSelection()

  useCounts(tabKey, countsContext, props)

  useEffect(() => {
    if (dataContext.isLoading) return
    if (dataContext.isRefetching) return
    if (!dataContext.response) return

    const { data, metadata } = dataContext.response
    const issuesByProject = data.reduce(
      (acc, item: any) => {
        const { projectId } = item

        if (!acc[projectId]) acc[projectId] = []

        acc[projectId].push(item)

        return acc
      },
      {} as Record<string, any[]>
    )

    Object.keys(issuesByProject).forEach((projectId: string) => {
      dispatch(
        actions.setIdsNestedTable(issuesByProject[projectId], projectId, {
          issuesTotal: metadata?.total
        })
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.isLoading, dataContext.isRefetching, props.isActive])

  const [issueRecord, setIssueRecord] = useState<null | Issue>(null)

  return (
    <>
      <TabDrawerTemplate
        dataContext={dataContext}
        filtersContext={filtersContext}
        getColumnsTable={getColumnsTable}
        expandedRowRender={expandedRowRenderFunction}
        rowSelection={{
          type: "checkbox",
          hideSelectAll: true,
          selectedRowKeys: selectionState.selectedNestedIds,
          onSelect: (record, selected) => {
            dispatch(actions.onSelectRow(record.projectId, record.id, selected))
          }
        }}
        FilterComponent={
          <IssuesFilter
            t={t}
            includeApplicationFilter={props.includeApplicationFilter}
            exportQueryParams={exportQueryParams}
            {...filtersContext}
          />
        }
        asideFooter={
          <IssueActions
            showLoadingMessage
            params={filtersContext.params}
            getOptionsUseDismissIssues={() => (queryClient) => ({
              onSuccess: () => {
                queryClient.removeQueries({
                  predicate: predicateHelpers.includesKeysPredicate([
                    RESOURCES_NAMES.TRIAGE.PROJECTS
                  ])
                })
                return dataContext?.refetch?.()
              },
              onSettled: undefined
            })}
            componentName={`tab:${tabKey || ""}_drawer:${props.drawerId || ""}`}
          />
        }
        headerComponent={headerComponent}
        setIssueRecord={setIssueRecord}
      />
      {issueRecord && (
        <LabelModal
          placement="tooltip"
          component="triage-tab"
          record={issueRecord}
          onClose={() => setIssueRecord(null)}
        />
      )}
    </>
  )
}
