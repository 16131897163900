import type { TFunction } from "@/hooks/useTranslation"
import type { OptionFilter } from "@/organisms/SmartSearch/types/options.types"

export const getDismissReasonFilter = (t: TFunction): OptionFilter => ({
  key: "dismissReason",
  label: t("filters.dismissReason"),
  type: "input",
  helper: [
    {
      label: "Dismiss-Reason: value1",
      description:
        "Matches Dismiss Reason text as a substring (e.g. mitigated by)"
    }
  ]
})

export const buildQueryStringFromRecord = (
  params: Record<string, any>
): string => {
  const query = new URLSearchParams(params)

  return query.toString()
}
