import type { SortOrder } from "@/atoms"
import type { ORDER_BY_PROPS } from "@/hooks/useOrderBy"

export const getDefaultColumnParams = (
  dataIndex: string,
  sorter?: Omit<ORDER_BY_PROPS, "onSorterChange">,
  attributes: { customSorterName?: string } = {}
) => {
  return {
    dataIndex,
    key: dataIndex,
    ellipsis: true,
    ...(sorter
      ? {
          sorter: true,
          showSorterTooltip: false,
          defaultSortOrder:
            sorter.orderBy === dataIndex ? sorter.ascending : undefined,
          sortDirections: ["descend", "ascend", "descend"] as SortOrder[],
          ...attributes
        }
      : {})
  }
}

export const scrollToElement = (rowKey: string, index?: number) => {
  const element = document.querySelector(`[data-row-key="${rowKey}"]`)

  if (element) {
    const rect = element.getBoundingClientRect()
    const offsetProperty = getComputedStyle(element).getPropertyValue(
      "--offset-scroll-margin-top"
    )
    const offset = parseInt(offsetProperty, 10)

    if (rect.top < offset) {
      element.setAttribute("style", "background: var(--selected-bg-color)")

      if (index !== undefined)
        document.getElementById("PageWithTabsTemplate")?.scrollTo({
          top: 60 + index * 40,
          behavior: "smooth"
        })
      else
        element.scrollIntoView({
          behavior: "smooth",
          inline: "start"
        })

      setTimeout(() => {
        element.removeAttribute("style")
      }, 2000)
    }
  }
}
