import type { FindingsTriageResponse } from "@/api/useTriage.types"
import {
  ApplicativeImageFinding,
  BaseImageFinding,
  CodeRepoFinding,
  Finding,
  isCodeIssueType
} from "@/domain/issue"
export const getFindingsInfoForExpandRow = (
  response: FindingsTriageResponse
) => {
  const { data } = response || {}
  const finding: Finding = data?.[0]
  const emptyData = {
    codeRepoFindings: [],
    baseImageFindings: [],
    imageFindings: [],
    fixVersions: []
  }

  if (!finding) {
    return {
      metadata: {
        epss: 0,
        cvss: 0,
        packageName: "",
        packageVersions: [""],
        cwe: { id: "", description: "" },
        language: "unknown" as const,
        issueType: "Vulnerable_Package" as const
      },
      ...emptyData
    }
  }

  if (isCodeIssueType(finding.issueType)) {
    return { metadata: finding, ...emptyData }
  }

  const {
    cvss,
    epss,
    packageVersions,
    packageName,
    fixVersions,
    cwe,
    language,
    issueType
  } = finding
  const codeRepoFindings = data.filter(
    (item) => item.resource.type === "CodeRepository"
  ) as CodeRepoFinding[]
  const baseImageFindings = data.filter(
    (item) =>
      item.resource.type === "Image" &&
      item.introducedThroughInsights.fromBaseImage
  ) as BaseImageFinding[]
  const imageFindings = data.filter(
    (item) =>
      item.resource.type === "Image" &&
      !item.introducedThroughInsights.fromBaseImage
  ) as ApplicativeImageFinding[]

  return {
    metadata: {
      cvss,
      epss,
      packageVersions,
      packageName,
      cwe,
      language,
      issueType
    },
    fixVersions,
    codeRepoFindings,
    baseImageFindings,
    imageFindings
  }
}
